<template>
  <section class="block">
    <div class="list">
      <div class="shop" v-for="(shop, i) in props.list" :key="shop.id">
        <div class="index">{{ i + 1 }}</div>
        <a class="link" :href="`/store/${shop.id}.html`" :title="shop.name">
          <div class="img">
            <img :src="shop.imgs?.[0]" :alt="`pachinko ${shop.name}`" loading="lazy" />
          </div>
          <div class="info">
            <div class="name">{{ shop.name }}</div>
            <div class="address">{{ shop.address }}</div>
            <div class="fees">{{ shop.fees }}</div>
          </div>
          <div class="env">
            <span class="tag" v-for="text in shop.storeenv.split(/\s*\/\s*/)" v-if="shop.storeenv">{{ text }}</span>
            <span class="tag" v-for="text in shop.smokeenv.split(/\s*\/\s*/)" v-if="shop.smokeenv">{{ text }}</span>
          </div>
        </a>
      </div>
    </div>
  </section>
</template>

<script setup>
const props = defineProps(['list'])
</script>

<style lang="less" scoped>
.block {
  margin: 20 10px;
  .list {
    .shop {
      display: flex;
      flex-flow: row nowrap;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 30px;
        right: 0;
        height: 1px;
        background-color: #eee;
      }
      .index {
        flex: 0 0 30px;
        font-size: 12px;
        font-weight: bold;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #999;
      }
      .link {
        margin: 10px 0;
        flex: 1 1 auto;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        text-decoration: none;
        &:hover {
          background-color: #f6f6f6;
        }
      }
      .img {
        margin-right: 10px;
        flex: 0 0 auto;
        width: 18%;
        max-width: 100px;
        height: 100px;
        border: solid 1px #eee;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          display: block;
          max-width: 90%;
          max-height: 90%;
        }
      }
      .info {
        flex: 1 1 auto;
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: center;
        min-width: 40%;
        .name {
          font-weight: bold;
          font-size: 16px;
          line-height: 1.5;
          color: #000;
        }
        .address {
          font-size: 12px;
          line-height: 1.5;
          color: #666;
        }
        .fees {
          .address;
          color: rgb(175, 19, 19);
        }
      }
      .env {
        flex: 0 0 140px;
        margin-left: 5px;
        max-width: 30%;
        border-left: dashed 1px #ddd;
        padding-left: 5px;
        .tag {
          display: inline-block;
          font-size: 12px;
          line-height: 1.5;
          flex: 0 0 auto;
          margin: 2px 5px;
          color: #2eb872;
          box-shadow: #2eb872 0 0 0 1px;
          border-radius: 3px;
          padding: 0 5px
        }
      }
    }
  }
}
</style>